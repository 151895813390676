// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MailingList_mailingSection__NS_mT {\n  padding: 40px 0;\n  background-color: #00aeef;\n  text-align: center; }\n  .MailingList_mailingSection__NS_mT p {\n    margin: 0 0 40px 0;\n    color: #ffffff;\n    font-size: 20px; }\n    @media screen and (min-width: 750px) {\n      .MailingList_mailingSection__NS_mT p {\n        font-size: 25px; } }\n    @media screen and (min-width: 1000px) {\n      .MailingList_mailingSection__NS_mT p {\n        font-size: 30px; } }\n  .MailingList_mailingSection__NS_mT h2 {\n    margin: 0 0 40px 0;\n    padding: 0;\n    color: #ffffff;\n    font-size: 35px; }\n    @media screen and (min-width: 750px) {\n      .MailingList_mailingSection__NS_mT h2 {\n        font-size: 40px; } }\n    @media screen and (min-width: 1000px) {\n      .MailingList_mailingSection__NS_mT h2 {\n        font-size: 50px; } }\n", "",{"version":3,"sources":["webpack://src/components/MailingList/MailingList.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB,EAAA;EAHpB;IAMI,kBAAkB;IAClB,cAAc;IACd,eAAe,EAAA;IAEf;MAVJ;QAWM,eAAe,EAAA,EAMlB;IAHC;MAdJ;QAeM,eAAe,EAAA,EAElB;EAjBH;IAoBI,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,eAAe,EAAA;IAEf;MAzBJ;QA0BM,eAAe,EAAA,EAMlB;IAHC;MA7BJ;QA8BM,eAAe,EAAA,EAElB","sourcesContent":[".mailingSection {\n  padding: 40px 0;\n  background-color: #00aeef;\n  text-align: center;\n\n  p {\n    margin: 0 0 40px 0;\n    color: #ffffff;\n    font-size: 20px;\n\n    @media screen and (min-width: 750px) {\n      font-size: 25px;\n    }\n\n    @media screen and (min-width: 1000px) {\n      font-size: 30px;\n    }\n  }\n\n  h2 {\n    margin: 0 0 40px 0;\n    padding: 0;\n    color: #ffffff;\n    font-size: 35px;\n\n    @media screen and (min-width: 750px) {\n      font-size: 40px;\n    }\n\n    @media screen and (min-width: 1000px) {\n      font-size: 50px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mailingSection": "MailingList_mailingSection__NS_mT"
};
export default ___CSS_LOADER_EXPORT___;
