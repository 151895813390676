// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_overlay__1TgzI {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  text-align: right;\n  background-color: rgba(0, 0, 0, 0.8);\n  z-index: 100;\n  opacity: 0;\n  transition: 500ms;\n  pointer-events: none; }\n\n.Modal_modal__1vwov {\n  position: fixed;\n  top: 0;\n  right: 0;\n  padding: 30px;\n  height: 100%;\n  width: 300px;\n  background-color: #ffffff;\n  z-index: 200;\n  transform: translateX(100%);\n  transition: 500ms; }\n\n.Modal_textModal__ILRTK {\n  margin-top: 10px;\n  display: block;\n  text-align: center;\n  text-transform: uppercase;\n  color: #00aeef;\n  font-size: 18px; }\n\n.Modal_closeBtnIcon__1REnb {\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,oCAAoC;EACpC,YAAY;EAEZ,UAAU;EACV,iBAAiB;EACjB,oBAAoB,EAAA;;AAGtB;EACE,eAAe;EACf,MAAM;EACN,QAAQ;EACR,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,YAAY;EAEZ,2BAA2B;EAC3B,iBAAiB,EAAA;;AAGnB;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,eAAe,EAAA;;AAGjB;EACE,eAAe,EAAA","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  text-align: right;\n  background-color: rgba(0, 0, 0, 0.8);\n  z-index: 100;\n\n  opacity: 0;\n  transition: 500ms;\n  pointer-events: none;\n}\n\n.modal {\n  position: fixed;\n  top: 0;\n  right: 0;\n  padding: 30px;\n  height: 100%;\n  width: 300px;\n  background-color: #ffffff;\n  z-index: 200;\n\n  transform: translateX(100%);\n  transition: 500ms;\n}\n\n.textModal {\n  margin-top: 10px;\n  display: block;\n  text-align: center;\n  text-transform: uppercase;\n  color: #00aeef;\n  font-size: 18px;\n}\n\n.closeBtnIcon {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "Modal_overlay__1TgzI",
	"modal": "Modal_modal__1vwov",
	"textModal": "Modal_textModal__ILRTK",
	"closeBtnIcon": "Modal_closeBtnIcon__1REnb"
};
export default ___CSS_LOADER_EXPORT___;
