// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_footer__7dIj9 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0;\n  min-height: 100px;\n  background-color: #ffffff;\n  color: #292929;\n  font-size: 14px;\n  text-align: center; }\n", "",{"version":3,"sources":["webpack://src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EAEnB,SAAS;EACT,iBAAiB;EACjB,yBAAyB;EAEzB,cAAc;EACd,eAAe;EACf,kBAAkB,EAAA","sourcesContent":[".footer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  margin: 0;\n  min-height: 100px;\n  background-color: #ffffff;\n\n  color: #292929;\n  font-size: 14px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "Footer_footer__7dIj9"
};
export default ___CSS_LOADER_EXPORT___;
