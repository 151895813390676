// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__1v0yI {\n  position: relative;\n  padding: 0 50px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  min-height: 100px;\n  background-color: #ffffff; }\n  @media screen and (min-width: 750px) {\n    .Header_header__1v0yI {\n      padding: 0 75px; } }\n  @media screen and (min-width: 1000px) {\n    .Header_header__1v0yI {\n      padding: 0 102px; } }\n\n.Header_buttonMenu__2yqZ9 {\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n  outline: none; }\n", "",{"version":3,"sources":["webpack://src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EAEf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EAEnB,iBAAiB;EACjB,yBAAyB,EAAA;EAEzB;IAXF;MAYI,eAAe,EAAA,EAMlB;EAHC;IAfF;MAgBI,gBAAgB,EAAA,EAEnB;;AAED;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,aAAa,EAAA","sourcesContent":[".header {\n  position: relative;\n  padding: 0 50px;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  min-height: 100px;\n  background-color: #ffffff;\n\n  @media screen and (min-width: 750px) {\n    padding: 0 75px;\n  }\n\n  @media screen and (min-width: 1000px) {\n    padding: 0 102px;\n  }\n}\n\n.buttonMenu {\n  margin: 0;\n  padding: 0;\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__1v0yI",
	"buttonMenu": "Header_buttonMenu__2yqZ9"
};
export default ___CSS_LOADER_EXPORT___;
